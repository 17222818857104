.aster_btn {
    font-weight: bold !important;
    font-size: 16px !important;
    color: white !important;
    background-color: #181551 !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    outline: none !important;
    min-width: 100px !important;
    width: auto;
    height: 50px;
}

.aster_btn:hover {
    box-shadow: 4px 5px 5px -5px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}

.aster_btn_disabled {
    font-weight: bold !important;
    font-size: 16px !important;
    color: white !important;
    background-color: #181551 !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: not-allowed !important;
    outline: none !important;
    min-width: 100px !important;
    width: auto;
    height: 50px;
    opacity: 0.5;
}

.aster_btn_cancel {
    font-weight: bold !important;
    font-size: 16px !important;
    color: #181551 !important;
    border-radius: 5px !important;
    background-color: white !important;
    cursor: pointer !important;
    outline: none !important;
    min-width: 100px !important;
    width: auto;
    height: 50px;
    border: 2px solid #181551 !important;
}

.aster_btn_cancel_disabled {
    font-weight: bold !important;
    font-size: 16px !important;
    color: #181551 !important;
    background-color: white !important;
    border-radius: 5px !important;
    cursor: not-allowed !important;
    outline: none !important;
    min-width: 100px !important;
    width: auto;
    height: 50px;
    opacity: 0.5;
    border: 2px solid #181551 !important;
}

.nav-bar-button_new_folder {
    font-size: 15px !important;
    color: white !important;
    border-radius: 15px !important;
    cursor: pointer !important;
    outline: none !important;
    height: 48px;
    line-height: 7px !important;
    margin-top: 8px !important;
}

.nav-bar-button_new_folder:hover {
    background-color: #1DE5CB !important;
}

.active {
    background: #1DE5CB !important;
}

.aster-bg_color {
    background-color: #1DE5CB !important;
    color: white !important;
}

.aster-btn_border {
    color: #181551 !important;
    min-width: 100px !important;
    background-color: white !important;
    height: 50px;
    font-weight: bold !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    outline: none !important;
    width: auto;
    border: 2px solid #181551 !important;
}

.aster-btn_border-disabled {
    opacity: 0.5;
    background: white !important;
    border-color: #181551 !important;
    color: #181551 !important;
    min-width: 100px !important;
    background-color: white !important;
    height: 50px;
    font-weight: bold !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    outline: none !important;
    width: auto;
    height: 60px;
    padding-right: 24px !important;
}


.mat-radio-button .mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #181551 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: #181551 !important;
    background-color: #181551 !important;
}

.mat-radio-button .mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: rgba(24, 21, 81, 0.64) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #181551 !important;
}

.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: rgba(24, 21, 81, 0.64) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
    background-color: rgba(24, 21, 81, 0.64) !important;
}

.aster_accent {
    color: #181551 !important;
}

.aster_accent_disabled {
    color: rgba(0, 0, 0, .26);
}

.delete-popup {
    flex-direction: row;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 10px 10px;
}

.aster-modal-btn_validate {
    font-weight: bold !important;
    font-size: 16px !important;
    color: white !important;
    background-color: #181551 !important;
    border: none !important;
    border-radius: 45px !important;
    cursor: pointer !important;
    outline: none !important;
    width: auto;
    height: 43px;
}

.aster-modal-btn_validate-disabled {
    opacity: 0.5;
    background: white !important;
    border-color: #181551 !important;
    color: #181551 !important;
    min-width: 100px !important;
    background-color: white !important;
    height: 50px;
    font-weight: bold !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    outline: none !important;
    width: auto;
}

.aster-modal-btn_cancel {
    font-weight: bold !important;
    font-size: 16px !important;
    color: #181551 !important;
    border-radius: 5px !important;
    background-color: white !important;
    cursor: pointer !important;
    outline: none !important;
    width: auto;
    height: 50px;
    border: 2px solid #181551 !important;
}

.aster_link_cancel {
    font-weight: bold !important;
    font-size: 16px !important;
    color: #181551 !important;
    cursor: pointer !important;
    outline: none !important;
    text-decoration: underline;
    min-width: 167px !important;
    width: auto;
    height: 50px;
    margin-top: 17px !important;
}
.table-header.mat-header-cell{
    font-size: 12px;
    font-family: "Arial Black";
}
