.e-grid .e-gridheader .e-headercell {
    padding-right: 0;
    padding-left: 0;
    margin-right: 2px;
    margin-left: 2px;


}

.e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
    padding-right: 0;
    padding-left: 0;
    margin-right: 2px;
    margin-left: 2px;

}


.e-grid .e-gridheader tr th:last-child {
    padding-right: 0;
    padding-left: 0;
}

.e-grid .e-rowcell:last-child {
    padding-right: 0;
    padding-left: 0;
}

e-grid {
    font-family: Muli, Helvetica Neue, Arial, sans-serif;
}